import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllClaims, approveClaim, rejectClaim } from '../store/slice/visualSlice'
import Selection from '../components/ui/Selection'
import Loader from '../components/ui/Loader'
import Pagination from '../components/shared/Pagination'
const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')

const Claims = () => {
    const dispatch = useDispatch()
    const { data: allClaimsData, loading, error } = useSelector((state) => state.claims)

    useEffect(() => {
        dispatch(fetchAllClaims())
    }, [dispatch])

 

    const [selectedOption, setSelectedOption] = useState('All')
    const [filteredClaims, setFilteredClaims] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 20

    useEffect(() => {
        if (selectedOption === 'All') {
            setFilteredClaims(allClaimsData)
        } else {
            const filtered = allClaimsData.filter((claim) => claim.status === selectedOption)
            setFilteredClaims(filtered)
        }
        setCurrentPage(1)
    }, [selectedOption, allClaimsData])

    const options = [
        { value: 'All', label: 'All' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Rejected', label: 'Rejected' }
    ]

    const handleSelect = (value) => {
        setSelectedOption(value)
    }

    const handleStatusChange = async (claim, newStatus) => {
        try {
            if (newStatus === 'Approved') {
                await dispatch(approveClaim(claim._id)).unwrap()
            } else if (newStatus === 'Rejected') {
                await dispatch(rejectClaim(claim._id)).unwrap()
            }
            dispatch(fetchAllClaims())
        } catch (error) {
            console.error(`Error changing status to ${newStatus}:`, error)
        }
    }

    const renderStatusButton = (claim) => {
        switch (claim.status) {
            case 'Approved':
                return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>
            case 'Rejected':
                return <button className="bg-red-600 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Rejected</button>
            case 'Pending':
                return (
                    <Selection
                        options={[
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Rejected', label: 'Rejected' },
                            { value: 'Approved', label: 'Approved' }
                        ]}
                        selectedOption={claim.status}
                        onSelect={(value) => handleStatusChange(claim, value)}
                        className="bg-black text-white"
                    />
                )
            default:
                return claim.status
        }
    }

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = filteredClaims.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredClaims.length / itemsPerPage)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div className="w-full h-auto  p-3 bg-cover bg-center flex flex-col gap-4 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`,}}
        >
            <div className="flex flex-col gap-2">
                <h3 className="text-xl font-bold">Visual Claims</h3>
                <p className="text-md text-gray-500">Keep Track of Claims and their Status.....</p>
            </div>

            <div className="w-20">
                <Selection options={options} selectedOption={selectedOption} onSelect={handleSelect} />
            </div>

          <div className='max-w-[600px] lg:max-w-full overflow-x-auto '>
          <div className="overflow-y-auto max-h-screen">
          <table className="bg-white w-full shadow-lg ">
          <thead className='bg-black text-white sticky top-0'>
                    <tr>
                        <th>Username</th>
                        <th>Channel Name</th>
                        <th className="text-center">Asset</th>
                        <th>Video URL</th>
                        <th>Priority</th>
                        <th>Date</th>
                        <th className="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="7">
                                <Loader />
                            </td>
                        </tr>
                    ) : error ? (
                        <tr>
                            <td colSpan="7">Error: {error}</td>
                        </tr>
                    ) : currentItems.length > 0 ? (
                        currentItems.map((claim) => (
                            <tr key={claim._id}>
                                <td>
                                    <div className="flex gap-2 items-center ">
                                        <img
                                            src={claim.profile_img ? claim.profile_img : userImage}
                                            className="w-10 h-10 rounded-full"
                                            alt="User"
                                        />
                                        <div>
                                            <p className="font-semibold">{claim.creatorName}</p>
                                            <p className="text-gray-500">{claim.clientId}</p>
                                        </div>
                                    </div>
                                </td>

                                <td className='text-center'>{claim.channelName}</td>
                                <td className="text-center">{claim.assetsName}</td>
                                <td>
                                    <a href={claim.videoUrl} target="_blank" rel="noopener noreferrer">
                                        VideoURL
                                    </a>
                                </td>
                                <td>{claim.priority}</td>
                                <td>{new Date(claim.date).toLocaleDateString()}</td>
                                <td className="flex justify-center items-center">{renderStatusButton(claim)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">No claims found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {/* <div className="flex justify-center items-center mt-4">
                <nav>
                    <ul className="inline-flex items-center -space-x-px">
                        <li>
                            <button
                                className={`px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700`}
                                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                            >
                                Previous
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index}>
                                <button
                                    className={`px-3 py-2 leading-tight ${
                                        currentPage === index + 1 ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                                    } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li>
                            <button
                                className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700`}
                                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div> */}
             <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={paginate}
                    />
          </div>
          </div>

            
        </div>
    )
}

export default Claims
