import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from '../store/slice/usersSlice'
import Selection from '../components/ui/Selection'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs'
import axios from 'axios'
import Loader from '../components/ui/Loader'
const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')

const AllUsers = () => {
    const dispatch = useDispatch()
    const { allUserdata, loading, error } = useSelector((state) => state.users)

    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    const [selectedOption, setSelectedOption] = useState('All')
    const [filteredUsers, setFilteredUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 15;
    const maxDisplayedPages = 5;

    useEffect(() => {
        if (selectedOption === 'All') {
            setFilteredUsers(allUserdata)
        } else {
            const filtered = allUserdata.filter((user) => user.status === selectedOption)
            setFilteredUsers(filtered)
        }
        setCurrentPage(1)
    }, [selectedOption, allUserdata])

    const options = [
        { value: 'All', label: 'All' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Rejected', label: 'Rejected' }
    ]

    const handleSelect = (value) => {
        setSelectedOption(value)
    }

    const handleStatusChange = async (user, newStatus) => {
        try {
            const token = JSON.parse(localStorage.getItem('token'))
            const headers = {
                Authorization: token
            }

            if (newStatus === 'Approved') {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/approve/${user._id}`, {}, { headers })
            } else if (newStatus === 'Rejected') {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/api/reject/${user._id}`, {}, { headers })
            }
            dispatch(fetchUsers())
        } catch (error) {
            console.error(`Error changing status to ${newStatus}:`, error)
        }
    }

    const renderStatusButton = (user) => {
        switch (user.status) {
            case 'Approved':
                return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>
            case 'Rejected':
                return <button className="bg-red-600 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Rejected</button>
            case 'Pending':
                return (
                    <Selection
                        options={[
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Rejected', label: 'Rejected' },
                            { value: 'Approved', label: 'Approved' }
                        ]}
                        selectedOption={user.status}
                        onSelect={(value) => handleStatusChange(user, value)}
                        className="bg-black text-white"
                    />
                )
            default:
                return user.status
        }
    }

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div className="w-full h-auto bg-cover bg-center p-3 flex flex-col gap-4 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`,}}
        >
            <div className="flex flex-col gap-2">
                <h3 className="text-xl font-bold">All Clients</h3>
                <p className="text-md text-gray-500">Keep Track of Clients and Contacts.....</p>
            </div>

            <div className="w-20">
                <Selection options={options} selectedOption={selectedOption} onSelect={handleSelect} />
            </div>

           <div className='max-w-[600px] lg:max-w-full overflow-x-auto shadow-lg'>
           <table className="bg-white w-full ">
                <thead className='text-white bg-black'>
                    <tr>
                        <th>Name</th>
                        <th className='text-center'>Email</th>
                        <th>Phone</th>
                        <th>Label Name</th>
                     
                        <th>Region</th>
                        <th className="flex justify-center items-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="6">
                                <Loader />
                            </td>
                        </tr>
                    ) : error ? (
                        <tr>
                            <td colSpan="6">Error: {error}</td>
                        </tr>
                    ) : currentItems.length > 0 ? (
                        currentItems.map((user) => (
                            <tr key={user._id}>
                                <td>
                                    {' '}
                                    <div className="flex gap-2 items-center ">
                                        <img
                                            src={user.profile_img ? user.profile_img : userImage}
                                            className="w-10 h-10 rounded-full"
                                            alt="User"
                                        />
                                        <div>
                                            <p className="font-semibold">{user.fullName}</p>
                                            <p className="text-gray-500">{user.clientID}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-center'>{user.email}</td>
                                <td>{user.mobileNumber}</td>
                                <td>{user.labelName}</td>
                               
                                <td>{user.region}</td>
                                <td className="flex justify-center items-center">{renderStatusButton(user)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No users found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
           </div>

            <div className="flex justify-center items-center mt-4">
            <nav>
                    <ul className="inline-flex items-center -space-x-px">
                        {currentPage > 1 && (
                           <div
                           className="mx-1 px-3 py-1 rounded bg-white text-gray-700 cursor-pointer"
                           onClick={() => paginate(currentPage - 1)}
                       >
                           <BsChevronDoubleLeft className="text-2xl" />
                       </div>
                        )}

                        {Array.from({ length: totalPages }, (_, index) => {
                            const pageIndex = index + 1;

                            // Show the first five pages
                            if (pageIndex <= maxDisplayedPages || pageIndex === totalPages) {
                                return (
                                    <li key={pageIndex}>
                                        <button
                                            className={`mx-1 px-3 py-1 rounded ${
                                                currentPage === pageIndex ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
                                            }`}
                                            onClick={() => paginate(pageIndex)}
                                        >
                                            {pageIndex}
                                        </button>
                                    </li>
                                );
                            }

                            // Show ellipsis if needed
                            if (pageIndex === maxDisplayedPages + 1 && currentPage < totalPages - 1) {
                                return <li key={pageIndex}>...</li>;
                            }

                            // Always show the last page and the current page if greater than maxDisplayedPages
                            if (pageIndex === totalPages || (currentPage > maxDisplayedPages && pageIndex === currentPage)) {
                                return (
                                    <li key={pageIndex}>
                                        <button
                                            className={`px-3 py-2 leading-tight ${
                                                currentPage === pageIndex ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                            onClick={() => paginate(pageIndex)}
                                        >
                                            {pageIndex}
                                        </button>
                                    </li>
                                );
                            }

                            return null;
                        })}

                        {currentPage < totalPages && (
                          <div
                          className="mx-1 px-3 py-1 rounded bg-white text-gray-700 cursor-pointer"
                          onClick={() => paginate(currentPage + 1)}
                      >
                          <BsChevronDoubleRight className="text-2xl" />
                      </div>
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default AllUsers
