import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchAllClaimReleaseRequests,
    approveClaimReleaseRequest,
    rejectClaimReleaseRequest
} from '../store/slice/releaseSlice'
import Selection from '../components/ui/Selection'
import Loader from '../components/ui/Loader'
import Pagination from '../components/shared/Pagination'
const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')


const VisualClaimRelease = () => {
    const dispatch = useDispatch()
    const { data: allUserdata, loading, error } = useSelector((state) => state.release)

    useEffect(() => {
        dispatch(fetchAllClaimReleaseRequests())
    }, [dispatch])

    const [selectedOption, setSelectedOption] = useState('All')
    const [filteredUsers, setFilteredUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 20

    useEffect(() => {
        if (selectedOption === 'All') {
            setFilteredUsers(allUserdata)
        } else {
            const filtered = allUserdata.filter((release) => release.status === selectedOption)
            setFilteredUsers(filtered)
        }
        setCurrentPage(1)
    }, [selectedOption, allUserdata])

    const options = [
        { value: 'All', label: 'All' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Rejected', label: 'Rejected' }
    ]

    const handleSelect = (value) => {
        setSelectedOption(value)
    }

    const handleStatusChange = async (release, newStatus) => {
        try {
            if (newStatus === 'Approved') {
                await dispatch(approveClaimReleaseRequest(release._id)).unwrap()
            } else if (newStatus === 'Rejected') {
                await dispatch(rejectClaimReleaseRequest(release._id)).unwrap()
            }
            setFilteredUsers((prevState) =>
                prevState.map((user) => (user._id === release._id ? { ...user, status: newStatus } : user))
            )
        } catch (error) {
            console.error(`Error changing status to ${newStatus}:`, error)
        }
    }

    const renderStatusButton = (release) => {
        switch (release.status) {
            case 'Approved':
                return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>
            case 'Rejected':
                return <button className="bg-red-600 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Rejected</button>
            case 'Pending':
                return (
                    <Selection
                        options={[
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Rejected', label: 'Rejected' },
                            { value: 'Approved', label: 'Approved' }
                        ]}
                        selectedOption={release.status}
                        onSelect={(value) => handleStatusChange(release, value)}
                        className="bg-black text-white"
                    />
                )
            default:
                return release.status
        }
    }

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div className="w-full h-auto bg-cover bg-center  p-3 flex flex-col gap-4 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`,}}
        >
            <div className="flex flex-col gap-2">
                <h3 className="text-xl font-bold">Visual Claim Release Request</h3>
                <p className="text-md text-gray-500">Keep Track of Artist Channels and Status.....</p>
            </div>

            <div className="w-20">
                <Selection options={options} selectedOption={selectedOption} onSelect={handleSelect} />
            </div>

            <table className="bg-white shadow-lg w-full">
                <thead className='text-white bg-black'>
                    <tr>
                        <th className="px-4 py-2">Username</th>
                        <th className="px-4 py-2">Content ID</th>
                        {/* <th className="px-4 py-2 text-center">Asset</th> */}
                        <th className="px-4 py-2">Video URL</th>
                        <th className="px-4 py-2">Date</th>
                        <th className="px-4 py-2">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="7">
                                <Loader />
                            </td>
                        </tr>
                    ) : error ? (
                        <tr>
                            <td colSpan="6" className="text-center p-4">
                                Error: {error}
                            </td>
                        </tr>
                    ) : currentItems.length > 0 ? (
                        currentItems.map((release) => (
                            <tr key={release._id}>
                                <td className="px-4 py-2 flex items-center">
                                    <img
                                        src={release.profile_img ? release.profile_img : userImage}
                                        className="w-10 h-10 rounded-full mr-2"
                                        alt="User"
                                    />
                                    <div>
                                        <p className="font-semibold">{release.creatorName}</p>
                                        <p className="text-gray-500">{release.clientId}</p>
                                    </div>
                                </td>
                                <td className="px-4 py-2">{release.contentIdBy}</td>
                                {/* <td className="px-4 py-2">{release.assetsName}</td> */}
                                <td className="px-4 py-2">
                                    <a
                                        href={release.videoUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:underline"
                                    >
                                        VideoURL
                                    </a>
                                </td>
                                <td className="px-4 py-2">{new Date(release.date).toLocaleDateString()}</td>
                                <td className="px-4 py-2">{renderStatusButton(release)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center p-4">
                                No users found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
            />

            {/* <div className="flex justify-center items-center mt-4">
                <nav>
                    <ul className="inline-flex items-center -space-x-px">
                        <li>
                            <button
                                className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                            >
                                Previous
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index}>
                                <button
                                    className={`px-3 py-2 leading-tight ${
                                        currentPage === index + 1 ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                                    } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li>
                            <button
                                className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
                                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div> */}
        </div>
    )
}

export default VisualClaimRelease
