import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchArtistChannelVerifications,
    approveArtistChannelVerification,
    rejectArtistChannelVerification
} from '../store/slice/artistSlice'
import Selection from '../components/ui/Selection'
import Loader from '../components/ui/Loader'
import { Table, Tbody,Thead,Th, Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const userImage = require('../images/download.jpeg')
const backgroundImage= require ('../images/Backround.jpg')

const ArtistChannel = () => {
    const dispatch = useDispatch()
    const { data: allUserdata, loading, error } = useSelector((state) => state.artist)

    useEffect(() => {
        dispatch(fetchArtistChannelVerifications())
    }, [dispatch])

    const [selectedOption, setSelectedOption] = useState('All')
    const [filteredUsers, setFilteredUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 15

    useEffect(() => {
        if (selectedOption === 'All') {
            setFilteredUsers(allUserdata)
        } else {
            const filtered = allUserdata.filter((user) => user.status === selectedOption)
            setFilteredUsers(filtered)
        }
        setCurrentPage(1)
    }, [selectedOption, allUserdata])

    const options = [
        { value: 'All', label: 'All' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Rejected', label: 'Rejected' }
    ]

    const handleSelect = (value) => {
        setSelectedOption(value)
    }

    const handleStatusChange = async (user, newStatus) => {
        try {
            if (newStatus === 'Approved') {
                await dispatch(approveArtistChannelVerification(user._id)).unwrap()
            } else if (newStatus === 'Rejected') {
                await dispatch(rejectArtistChannelVerification(user._id)).unwrap()
            }
            dispatch(fetchArtistChannelVerifications())
        } catch (error) {
            console.error(`Error changing status to ${newStatus}:`, error)
        }
    }

    const renderStatusButton = (user) => {
        switch (user.status) {
            case 'Approved':
                return <button className="bg-green-500 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Approved</button>
            case 'Rejected':
                return <button className="bg-red-600 w-[80px] h-8 text-white px-2 py-1 rounded-sm">Rejected</button>
            case 'Pending':
                return (
                    <Selection
                        options={[
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Rejected', label: 'Rejected' },
                            { value: 'Approved', label: 'Approved' }
                        ]}
                        selectedOption={user.status}
                        onSelect={(value) => handleStatusChange(user, value)}
                        className="bg-black text-white"
                    />
                )
            default:
                return user.status
        }
    }

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const renderPageNumbers = () => {
        const pageNumbers = []
        const maxPageButtons = 5

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <li key={i}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === i ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(i)}
                        >
                            {i}
                        </button>
                    </li>
                )
            }
        } else {
            const startPage = Math.max(1, currentPage - 2)
            const endPage = Math.min(totalPages, currentPage + 2)

            if (startPage > 1) {
                pageNumbers.push(
                    <li key={1}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === 1 ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(1)}
                        >
                            1
                        </button>
                    </li>
                )

                if (startPage > 2) {
                    pageNumbers.push(
                        <li key="ellipsis-start">
                            <span className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">
                                .....
                            </span>
                        </li>
                    )
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <li key={i}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === i ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(i)}
                        >
                            {i}
                        </button>
                    </li>
                )
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    pageNumbers.push(
                        <li key="ellipsis-end">
                            <span className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">
                                .....
                            </span>
                        </li>
                    )
                }

                pageNumbers.push(
                    <li key={totalPages}>
                        <button
                            className={`px-3 py-2 leading-tight ${
                                currentPage === totalPages ? 'text-white bg-blue-500' : 'text-gray-500 bg-white'
                            } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            onClick={() => paginate(totalPages)}
                        >
                            {totalPages}
                        </button>
                    </li>
                )
            }
        }

        return pageNumbers
    }

    return (
        <div className="w-full h-screen bg-cover bg-center p-3 flex flex-col gap-4 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`,}}
        >
            <div className="flex flex-col gap-2">
                <h3 className="text-xl font-bold">OAC Verification</h3>
                <p className="text-md text-gray-500">Keep Track of Artist Channels and Status.....</p>
            </div>

            <div className="w-20">
                <Selection options={options} selectedOption={selectedOption} onSelect={handleSelect} />
            </div>

            <div className="max-w-[600px] lg:max-w-full  px-2">
                <Table className="bg-white w-full shadow-lg ">
                    <Thead className='bg-black text-white'>
                        <Tr>
                            <Th>Username</Th>
                            <Th className='text-center'>Email</Th>
                            <Th className='text-center'>Artist Name</Th>
                            <Th className='text-center'>O&O Channel</Th>
                            <Th className='text-center'>Artist Topic Channel</Th>
                            <Th className='text-center'>Official Music Video ID</Th>

                            <Th className="flex justify-center items-center">Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {loading ? (
                            <Tr>
                                <Td colSpan="7">
                                    <Loader />
                                </Td>
                            </Tr>
                        ) : error ? (
                            <Tr>
                                <Td colSpan="7">Error: {error}</Td>
                            </Tr>
                        ) : currentItems.length > 0 ? (
                            currentItems.map((user) => (
                                <Tr key={user._id}>
                                    <Td >
                                        <div className="flex gap-2 items-center pr-4">
                                            <img
                                                src={user.profile_img ? user.profile_img : userImage}
                                                className="w-10 h-10 rounded-full"
                                                alt="User"
                                            />
                                            <div>
                                                <p className="font-semibold">{user.fullName}</p>
                                                <p className="text-gray-500">{user.clientID}</p>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td className='text-center '>{user.email}</Td>
                                    <Td className='text-center'>{user.artistName}</Td>
                                    <Td className='text-center'>{user.oAndOChannel}</Td>
                                    <Td className='text-center'>{user.artistTopicChannel}</Td>
                                    <Td className='text-center'>{user.officialMusicVideoId}</Td>
                                   
                                    <Td className="flex justify-center items-center">{renderStatusButton(user)}</Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan="7">No users found.</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </div>

            <div className="flex justify-center items-center mt-4">
                <nav>
                    <ul className="inline-flex items-center -space-x-px">
                        <li>
                            <button
                                className={`px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700`}
                                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                            >
                                Previous
                            </button>
                        </li>
                        {renderPageNumbers()}
                        <li>
                            <button
                                className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700`}
                                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default ArtistChannel
